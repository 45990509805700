import gql from 'graphql-tag';

export const GET_MY_USER = gql`
  query getMyUser {
    getMyUser {
      userId
      firstName
      lastName
      avatar {
        id
        file {
          bucket
          region
          key
        }
      }
      birthDate
      location {
        city
      }
      description
      phoneNumber
      familySituation
      communications {
        post
        notifications {
          general
        }
      }
      acceptTerms
      state
      lastSeenAt
    }
  }
`;

export const CREATE_PROPERTY = gql`
  mutation createProperty($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      propertyId
    }
  }
`;

export const CREATE_LEASE = gql`
  mutation createLease($input: CreateLeaseInput!) {
    createLease(input: $input) {
      propertyId
      leaseId
      tenants
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      propertyId
      leaseId
      contractId
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      contractId
    }
  }
`;

export const LIST_PROPERTIES = gql`
  query listProperties {
    listProperties(limit: 100) {
      items {
        propertyId
        location {
          houseNumber
          streetAddress
          city
          state
          zipCode
          country
        }
        state
        updatedAt
        propertyType
        unitType
        notes
        photos {
          id
          file {
            bucket
            region
            key
          }
        }
        rooms {
          id
        }
        tenants {
          user {
            userId
          }
        }
        owner {
          users {
            userId
          }
        }
      }
    }
  }
`;

export const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdatePropertyInput!) {
    updateProperty(input: $input) {
      propertyId
      location {
        houseNumber
        streetAddress
        city
        state
        zipCode
        country
      }
      state
      propertyType
      unitType
      numberOfRooms
      numberOfBedrooms
      numberOfBathrooms
      numberOfBalconies
      numberOfFloors
      numberOfParkings
      surfaceArea
      constructionYear
      energyLabel
      notes
      photos {
        id
        file {
          bucket
          region
          key
        }
      }
    }
  }
`;

export const GET_PROPERTY = gql`
  query getProperty($propertyId: ID!) {
    getProperty(propertyId: $propertyId) {
      propertyId
      location {
        houseNumber
        streetAddress
        city
        state
        zipCode
        country
      }
      createdAt
      updatedAt
      state
      propertyType
      unitType
      numberOfRooms
      numberOfBedrooms
      numberOfBathrooms
      numberOfBalconies
      numberOfFloors
      numberOfParkings
      surfaceArea
      constructionYear
      energyLabel
      energyLabelDocument {
        id
        file {
          bucket
          region
          key
        }
      }
      notes
      rooms {
        description
        floor
        id
        image {
          file {
            bucket
            key
            region
          }
          id
        }
        items {
          id
          name
          notes
          type
          images {
            id
            file {
              bucket
              key
              region
            }
          }
        }
        name
        roomType
      }
      photos {
        id
        file {
          bucket
          region
          key
        }
      }
      tenants {
        user {
          userId
          firstName
          lastName
          phoneNumber
          avatar {
            id
            file {
              bucket
              region
              key
            }
          }
        }
        location {
          houseNumber
          streetAddress
          city
          state
          zipCode
          country
        }
        notes
      }
      owner {
        companyName
        companyId
        firstName
        lastName
        iban
        ibanName
        location {
          houseNumber
          streetAddress
          city
          state
          zipCode
          country
        }
        ownerType
        notes
        users {
          userId
          firstName
          lastName
          phoneNumber
          avatar {
            id
            file {
              bucket
              region
              key
            }
          }
        }
      }
      leases {
        createdAt
        leaseId
        deposit
        identityVerification {
          verificationId
        }
        propertyId
        signState
        state
        updatedAt
        passBaseMetaData
        checkIn {
          checkInId
        }
        checkOut {
          checkOutId
        }
        contract {
          contractId
          createdAt
          contractType
          noticePeriod
          state
          startDate
          endDate
          leaseAmount
          depositAmount
          serviceCost {
            service
            heating
            gas
            electricity
            media
            furniture
          }
          leaseIndex
          increaseType
          firstIncreaseDate
          leaseOptions {
            petsAllowed
          }
          sections {
            sectionId
            order
            title
            body
          }
        }
      }
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty($input: DeletePropertyInput!) {
    deleteProperty(input: $input) {
      propertyId
    }
  }
`;

export const ADD_USER_TO_PROPERTY = gql`
  mutation addUserToProperty($input: AddUserToPropertyInput!) {
    addUserToProperty(input: $input) {
      propertyId
      owner {
        users {
          userId
          firstName
          lastName
          phoneNumber
        }
      }
      tenants {
        user {
          userId
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;

export const UPDATE_PROPERTY_ROOMS = gql`
  mutation updatePropertyRooms($input: UpdatePropertyRoomsInput!) {
    updatePropertyRooms(input: $input) {
      propertyId
    }
  }
`;

export const LIST_DOCUMENTS = gql`
  query getPropertyDocuments {
    getPropertyDocuments {
      items {
        documentId
      }
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation getUploadUrl($input: UploadUrlInput!) {
    getUploadUrl(input: $input) {
      signedUrl
      key
    }
  }
`;

export const ADD_ITEM_TO_ROOM = gql`
  mutation addItemToRoom($input: AddItemToRoomInput!) {
    addItemToRoom(input: $input) {
      propertyId
    }
  }
`;

export const ADD_ROOM = gql`
  mutation addRoom($input: AddRoomInput) {
    addRoom(input: $input) {
      propertyId
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom($input: UpdateRoomInput) {
    updateRoom(input: $input) {
      propertyId
    }
  }
`;

export const UPDATE_LEASE = gql`
  mutation updateLease($input: UpdateLeaseInput) {
    updateLease(input: $input) {
      propertyId
      leaseId
    }
  }
`;

export const GET_MY_CHATS = gql`
  query getMyChats {
    getMyChats {
      items {
        chatId
        chatInfo {
          chatName
          chatType
          members {
            userId
            firstName
            lastName
            avatar {
              file {
                key
              }
            }
          }
        }
        lastMessage {
          chatId
          createdAt
          messageId
          isRead
          message
          user {
            userId
            firstName
            lastName
            avatar {
              file {
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CHAT = gql`
  query getChat($chatId: ID!) {
    getChat(chatId: $chatId) {
      chatInfo {
        chatName
        chatType
        members {
          userId
          firstName
          lastName
          avatar {
            file {
              key
            }
          }
        }
      }
      items {
        createdAt
        message
        messageId
        sortKey
        isRead
        user {
          userId
          firstName
          lastName
          avatar {
            file {
              key
            }
          }
        }
        thread {
          createdAt
          messageId
          message
          isRead
          sortKey
          user {
            userId
            firstName
            lastName
            avatar {
              file {
                key
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
