import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Toolbar,
} from '@mui/material';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = props => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'primary.main',
        color: 'text.secondary',
      }}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none',
            },
          }}>
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none',
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none',
            },
          }}>
          {' '}
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/dashboard"
            underline="none"
            variant="body1">
            Login
          </Link>
          {/* <Link
                        color="textSecondary"
                        component={RouterLink}
                        to="/browse"
                        underline="none"
                        variant="body1"
                    >
                        Browse
                    </Link>
                    <Chip
                        color="primary"
                        label="NEW"
                        size="small"
                        sx={{
                            maxHeight: 20,
                            ml: 1,
                            mr: 2,
                        }}
                    /> */}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
