import type { FC } from 'react';
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import Avatar from '@mui/material/Avatar';

interface AvatarProps {
  sx?: SxProps<Theme>;
  avatar: any;
}
const UserAvatar: FC<AvatarProps> = props => (
  <Avatar
    src={`${process.env.REACT_APP_MEDIA_URL}/${props.avatar?.file.key}`}
    {...props}
  />
);

export default UserAvatar;
