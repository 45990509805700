/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';

const LoginAmplify: FC = props => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth() as any;

  return (
    <Formik
      initialValues={{
        phone_number: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        phone_number: Yup.string().phone().required('Phone number is required'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.phone_number);
          // console.log(user);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            navigate('/authentication/verify-code');
          }
        } catch (err) {
          console.error(err);

          // if (err.code === 'UserNotConfirmedException') {
          //   navigate('/authentication/verify-code', {
          //     state: {
          //       username: values.phone_number,
          //     },
          //   });
          //   return;
          // }

          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.phone_number && errors.phone_number)}
            fullWidth
            helperText={touched.phone_number && errors.phone_number}
            label="Phone Number"
            margin="normal"
            name="phone_number"
            onBlur={handleBlur}
            onChange={handleChange}
            type="tel"
            value={values.phone_number}
            variant="outlined"
          />
          {/* <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          /> */}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
