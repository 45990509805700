// import DashboardLayout from './components/DashboardLayout';

// import UsersList from './components/Users/UsersList';
// import Properties from './pages/Properties';

// const routes = [
//     {
//         path: '/',
//         element: <DashboardLayout />,
//         children: [
//             {
//                 path: 'properties',
//                 element: <Properties />,
//                 children: [
//                     { path: '/', element: <PropertiesListResult /> },
//                     { path: 'new', element: <AddPropertyForm /> },
//                     { path: ':propertyId/:tabs', element: <EditPropertyPage /> },
//                 ],
//             },
//             { path: 'users', element: <UsersList /> },
//             { path: 'dashboard', element: <Dashboard /> },
//             { path: '/', element: <Navigate to="/dashboard" /> },
//             { path: '*', element: <Navigate to="/404" /> },
//         ],
//     },
// ];

// export default routes;

import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerDetails = Loadable(
  lazy(() => import('./pages/dashboard/CustomerDetails'))
);
const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit'))
);
const CustomerList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerList'))
);
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceDetails'))
);
const InvoiceList = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceList'))
);
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails'))
);
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProductCreate'))
);
const ProductList = Loadable(
  lazy(() => import('./pages/dashboard/ProductList'))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const PropertyBrowse = Loadable(
  lazy(() => import('./pages/dashboard/PropertyBrowse'))
);
const PropertyCreate = Loadable(
  lazy(() => import('./pages/dashboard/PropertyCreate'))
);
const PropertyDetails = Loadable(
  lazy(() => import('./pages/dashboard/PropertyDetails'))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(
  lazy(() => import('./pages/dashboard/SocialProfile'))
);

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      // {
      //     path: 'register',
      //     element: (
      //         <GuestGuard>
      //             <Register />
      //         </GuestGuard>
      //     ),
      // },
      // {
      //     path: 'register-unguarded',
      //     element: <Register />,
      // },
      {
        path: 'verify-code',
        element: (
          <GuestGuard>
            <VerifyCode />
          </GuestGuard>
        ),
      },
    ],
  },

  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            element: <Chat />,
          },
          {
            path: 'new',
            element: <Chat />,
          },
          {
            path: ':threadKey',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <CustomerList />,
          },
          {
            path: ':customerId',
            element: <CustomerDetails />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            element: <InvoiceList />,
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: 'kanban',
        element: <Kanban />,
      },
      {
        path: 'mail',
        children: [
          {
            path: '',
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
          {
            path: 'label/:customLabel',
            element: <Mail />,
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />,
          },
          {
            path: ':systemLabel',
            element: <Mail />,
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <OrderList />,
          },
          {
            path: ':orderId',
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: 'finance',
        element: <Finance />,
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <ProductList />,
          },
          {
            path: 'new',
            element: <ProductCreate />,
          },
        ],
      },
      {
        path: 'properties',
        children: [
          {
            path: '',
            element: <PropertyBrowse />,
          },
          {
            path: 'new',
            element: <PropertyCreate />,
          },
          {
            path: ':propertyId',
            element: <PropertyDetails />,
          },
        ],
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeed />,
          },
          {
            path: 'profile',
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },

  {
    path: '*',
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
