import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,

    // oauth: {
    //     domain: `${process.env.REACT_APP_AUTH_DOMAIN}.auth.eu-west-1.amazoncognito.com`,
    //     scope: ['phone', 'profile', 'openid'],
    //     redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
    //     redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
    //     responseType: 'code',
    // },
  },
});

const authLink = setContext(async () => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  console.log(`AUTHORIZATION`, token);
  return {
    headers: { Authorization: token },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([
    authLink,
    new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }),
  ]),
});

export default client;
