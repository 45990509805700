import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
  colored?: string;
}

const LogoRoot = styled('svg')``;

const ColoredLogo = () => (
  <>
    <path
      d="M6.863 0a6.863 6.863 0 0 1 6.863 6.863v29.702H0V6.863A6.863 6.863 0 0 1 6.863 0z"
      fill="url(#a)"
    />
    <path
      d="m37.498 26.036-2.337-.44c-3.433-.645-5.747 1.03-8.757 2.969l-9.855 6.391a5.999 5.999 0 0 0-2.735 5.033V52.847a3.82 3.82 0 0 1-3.82 3.82H3.82A3.82 3.82 0 0 1 0 52.847V35.603c0-2.205 1.046-4.248 2.753-5.38l18.26-12.1a3.82 3.82 0 0 1 4.177-.066l12.308 7.98z"
      fill="#4094E1"
    />
    <path
      d="m9.096 26.111 11.348-7.989a6.549 6.549 0 0 1 7.634.069l14.709 10.75a10.369 10.369 0 0 1 4.25 8.372V52.3a4.366 4.366 0 0 1-4.366 4.366h-4.618a4.366 4.366 0 0 1-4.366-4.34l-.058-9.499c-.005-.898-.002-1.76.005-2.525.017-1.884-.812-3.68-2.306-4.827a459.033 459.033 0 0 0-8.302-6.206 17.962 17.962 0 0 0-13.93-3.159z"
      fill="url(#b)"
    />
    <defs>
      <linearGradient
        id="a"
        x1="6.863"
        y1="7.163"
        x2="6.863"
        y2="36.565"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#4094E1" />
        <stop offset="1" stopColor="#1E6DAF" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="20.947"
        y1="23.443"
        x2="45.166"
        y2="61.224"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A3DDFF" />
        <stop offset="1" stopColor="#4094E1" />
      </linearGradient>
    </defs>
  </>
);
const WhiteLogo = () => (
  <>
    <path
      d="M7.41736 0.269287C11.2078 0.269287 14.2805 3.34203 14.2805 7.13244V36.834H0.554199V7.13244C0.554199 3.34203 3.62694 0.269287 7.41736 0.269287Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M38.0522 26.3057L35.715 25.8661C32.2825 25.2205 29.9682 26.8959 26.9581 28.8343L17.1033 35.2256C15.3976 36.3319 14.3681 38.226 14.3681 40.2591C14.3681 40.5283 14.3681 40.8178 14.3681 41.1289V53.1163C14.3681 55.2262 12.6577 56.9365 10.5479 56.9365H4.37439C2.26456 56.9365 0.554199 55.2262 0.554199 53.1163V35.8723C0.554199 33.6674 1.59971 31.6247 3.30744 30.493L21.5678 18.3921C22.8489 17.5431 24.4419 17.5181 25.7442 18.3266L38.0522 26.3057Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M9.65027 26.3808L20.9983 18.392C23.2945 16.7756 26.3657 16.8029 28.6327 18.46L43.3409 29.2109C46.0121 31.1634 47.5911 34.2734 47.5911 37.5821V52.5706C47.5911 54.9818 45.6364 56.9365 43.2252 56.9365H38.6069C36.2061 56.9365 34.2557 54.998 34.2411 52.5972L34.1832 43.0976C34.1777 42.1996 34.1809 41.3375 34.188 40.5726C34.2056 38.6889 33.3763 36.8924 31.8819 35.7453C30.1234 34.3954 27.3695 32.3134 23.5802 29.5396C19.5732 26.6064 14.5306 25.4629 9.65027 26.3808Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.8418"
        y1="16.7093"
        x2="4.8418"
        y2="46.1821"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#4094E1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="38.0522"
        y1="17.7373"
        x2="10.9252"
        y2="36.1995"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#4094E1" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </>
);
const Logo: FC<LogoProps> = props => (
  <LogoRoot
    width="48"
    height="57"
    version="1.1"
    viewBox="0 0 48 57"
    fill="none"
    {...props}>
    {props.colored ? <ColoredLogo /> : <WhiteLogo />}
  </LogoRoot>
);

export default Logo;
